<template>
  <section class="bg-grey">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 my-4">
          <div class="card disen cardd">
            <div class="card-header bg-light">
              <h6 class="font-weight-bold mb-0">Perfil</h6>
            </div>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-2">
                      <a-avatar
                        :size="{
                          xs: 32,
                          sm: 40,
                          md: 64,
                          lg: 80,
                          xl: 100,
                          xxl: 124,
                        }"
                        :src="photo"
                      >
                      </a-avatar>
                      <a-upload
                        :file-list="file"
                        :remove="handleRemove"
                        :before-upload="beforeUpload"
                      >
                        <a-button
                          type="primary"
                          shape="circle"
                          style="position: absolute; right: 10%; top: 0"
                        >
                          <i class="icon ion-md-camera"></i>
                        </a-button>
                      </a-upload>
                    </div>
                    <div class="col-lg-10">
                      <h4 class="text-dark">Información</h4>
                      <div class="dropdown-divider"></div>
                      <div class="row py-2">
                        <div class="col-lg-6">
                          <h5 class="titles">Email</h5>
                          <div class="text-muted">
                            {{ user().email }}
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <h5 class="titles">Celular</h5>
                          <div class="text-muted">
                            {{ user().phone }}
                          </div>
                        </div>
                      </div>
                      <div class="row py-3">
                        <div class="col-lg-6">
                          <h5 class="titles">Nombre</h5>
                          <a-input
                            v-model:value="username"
                            placeholder="Nombre"
                          />
                        </div>
                        <div class="col-lg-6">
                          <h5 class="titles">Ubicación</h5>
                          <div class="text-muted">
                            {{ user().direction?.street }}
                          </div>
                        </div>
                      </div>
                      <div class="row py-3">
                        <div class="col-lg-12">
                          <h5 class="titles">Categorias</h5>
                          <a-select
                            mode="tags"
                            size="large"
                            placeholder="Please select"
                            v-model:value="categories"
                            style="width: 100%"
                          >
                            <a-select-option
                              v-for="c in categories_options"
                              :key="c"
                            >
                              {{ c }}
                            </a-select-option>
                          </a-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-3" style="justify-content: flex-end">
                <a-button
                  :disabled="loading()"
                  class="mr-3"
                  type="danger"
                  @click="reset"
                >
                  Reiniciar
                </a-button>
                <a-button :disabled="loading()" type="primary" @click="save">
                  Guardar cambios
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { reactive, toRefs, ref } from "vue";
export default {
  name: "Profile",
  setup() {
    const file = ref();
    const store = useStore();
    const state = reactive({
      email: store.getters.user.email,
      username: store.getters.user.username,
      photo: store.getters.user.photo,
      categories: store.getters.user.categories,
      categories_options: [
        "Restaurante",
        "Postres",
        "Mercado",
        "Farmacia",
        "Licores",
        "Tecnología",
        "Mascotas",
        "Moda",
        "Fitness",
        "Ayuda",
      ],
    });
    function user() {
      return store.getters.user;
    }
    function logout() {
      store.dispatch("logout");
    }
    async function save() {
      const user = {
        ...store.getters.user,
        username: state.username,
        image: file.value && file.value.length > 0 ? file.value[0] : null,
        categories: state.categories,
      };
      await store.dispatch("updateProfile", user);
      state.photo = store.getters.user.photo;
      file.value = [];
    }
    function reset() {
      file.value = [];
      state.photo = store.getters.user.photo;
      state.categories = store.getters.user.categories;
      state.username = store.getters.user.username;
    }
    function loading() {
      return store.getters.loading;
    }
    const beforeUpload = (f) => {
      file.value = [f];
      state.photo = URL.createObjectURL(f);
      return false;
    };
    const handleRemove = () => {
      state.photo = store.getters.user.photo;
      file.value = [];
    };
    return {
      file,
      beforeUpload,
      handleRemove,
      ...toRefs(state),
      user,
      logout,
      save,
      loading,
      reset,
    };
  },
};
</script>

<style scoped src="../styles/style.css"></style>